.pt--hero .paragraph-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.pt--hero .paragraph-inner .hero-body {
  margin-bottom: 20px;
  text-align: center;
}

.pt--hero .paragraph-inner .field--name-field-link {
  text-align: center;
}

.pt--hero.pg--vm--featured .paragraph-inner .hero-body h1, .pt--hero.pg--vm--featured .paragraph-inner .hero-body h2 {
  text-transform: none;
}

.pt--hero.pg--vm--featured .mt-tw-image {
  position: relative;
}

.pt--hero.pg--vm--featured .mt-tw-image:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #29363E;
  mix-blend-mode: multiply;
  opacity: .55;
  z-index: 1;
}
