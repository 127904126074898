//==============================================================================
// IMAGE ZOOM
//==============================================================================

@mixin zoom-image {
  @include transition;
  transform: scale(1.03);
}

@mixin img-color-overlay($color) {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color;
    mix-blend-mode: multiply;
    opacity: .55;
    z-index: 1;
  }
}
