@import "./_sass-essentials/essentials.scss";

.pt--hero {
  .paragraph-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-left: $l-gutter-half;
    padding-right: $l-gutter-half;

    .hero-body {
      margin-bottom: $co-mob;
      text-align: center;
    }

    .field--name-field-link {
      text-align: center;
    }
  }

  &.pg--vm--featured {
    .paragraph-inner {
      .hero-body {
        h1, h2 {
          text-transform: none;
        }
      }
    }

    .mt-tw-image {
      @include img-color-overlay($c-brand-secundary);
    }
  }
}
